import { DocumentReference, doc, getDoc, updateDoc } from 'firebase/firestore';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { db } from 'utils/firestore';
import { getSubscriptions as getStripeSubscriptions } from 'utils/stripe';
import consoleLogDev from 'utils/consoleLogDev';

import useGlobalState from 'hooks/useGlobalState';

import { IPrice, IProduct, IProfile, Nullable } from 'types/interfaces';
import { DEFAULT_PLAN_STORAGE_SIZE_IN_MB } from 'constants/common';
import { showErrorNotification, showSuccessfulNotification } from 'utils/notifications';

const useSubscription = () => {
  const { t } = useTranslation();
  const [globalState, setGlobalState] = useGlobalState();
  const { isSubscriptionLoading } = globalState;

  const updateProfile = useCallback(
    async (profile: Nullable<IProfile>, updatedData: Partial<IProfile>) => {
      if (!profile) return;

      try {
        const profileData = {
          ...profile,
          ...updatedData,
        };

        const profileDocRef = doc(db, 'profiles', profile?.uid);
        await updateDoc(profileDocRef, updatedData);
        setGlobalState({ profile: profileData });
      } catch (error) {
        consoleLogDev(error);
      }
    },
    [setGlobalState]
  );

  const refreshSubscriptionData = useCallback(
    async (profile: Nullable<IProfile>) => {
      if (isSubscriptionLoading || !profile) return;

      consoleLogDev('Checking the subscription status...');

      try {
        setGlobalState({
          isSubscriptionLoading: true,
        });

        const subscriptions = await getStripeSubscriptions();

        const hasAppSubscription = profile.hasAndroidSubscription || profile.hasiOSSubscription;
        const hasActiveOrRenewableSubscription = subscriptions.some(
          subscription => subscription.status !== 'canceled'
        );

        const activeStripeSubscription = subscriptions?.find(
          subscription => subscription.status === 'active' || subscription.status === 'trialing'
        );

        let activeStripeSubscriptionPlan = null;
        let activeStripeSubscriptionPrice = null;

        await updateProfile(profile, {
          hasWebSubscription: hasActiveOrRenewableSubscription,
        });

        if (activeStripeSubscription) {
          const subsciptionProductDoc = await getDoc(
            activeStripeSubscription.product as unknown as DocumentReference
          );
          const subsciptionPriceDoc = await getDoc(
            activeStripeSubscription.price as unknown as DocumentReference
          );

          activeStripeSubscriptionPlan = {
            ...(subsciptionProductDoc.data() as IProduct),
            productId: subsciptionProductDoc.id,
          };

          activeStripeSubscriptionPrice = {
            ...(subsciptionPriceDoc.data() as IPrice),
            priceId: subsciptionPriceDoc.id,
          };

          const subscriptionPlanStorageInMB = Number(
            activeStripeSubscriptionPlan.metadata.planStorageInMB
          );

          // Update plan storage in profile if user has a subscription and
          // his/hers plan storage isn't matching the subscription plan storage
          if (
            activeStripeSubscriptionPlan &&
            subscriptionPlanStorageInMB &&
            profile.planStorageInMB !== subscriptionPlanStorageInMB
          ) {
            await updateProfile(profile, {
              planStorageInMB: subscriptionPlanStorageInMB,
              hasWebSubscription: true,
            });

            showSuccessfulNotification(t('subscriptionSuccessMessage'));
          }
        } else if (
          !hasAppSubscription &&
          profile.planStorageInMB !== DEFAULT_PLAN_STORAGE_SIZE_IN_MB &&
          profile.planStorageInMB !== 100000 // in the early days there was a free lifetime subscription
        ) {
          await updateProfile(profile, {
            planStorageInMB: DEFAULT_PLAN_STORAGE_SIZE_IN_MB,
            hasWebSubscription: false,
          });

          showErrorNotification(t('subscriptionInactiveMessage'));
        }

        setGlobalState({
          hasActiveOrRenewableSubscription,
          subscription: activeStripeSubscription,
          subscriptionPlan: activeStripeSubscriptionPlan,
          subscriptionPrice: activeStripeSubscriptionPrice,
        });
      } catch (error) {
        consoleLogDev('Failed checking the subscription status', error);
      } finally {
        setGlobalState({
          isSubscriptionLoading: false,
        });
      }
    },
    [setGlobalState, updateProfile, isSubscriptionLoading, t]
  );

  return {
    hasActiveOrRenewableSubscription: globalState.hasActiveOrRenewableSubscription,
    subscription: globalState.subscription,
    isSubscriptionLoading: globalState.isSubscriptionLoading,
    subscriptionPlan: globalState.subscriptionPlan,
    refreshSubscriptionData,
  };
};

export default useSubscription;
