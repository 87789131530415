import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import CookieYesSettingsButton from 'components/CookieYesSettingsButton';

import { ReactComponent as IconHashare } from 'images/icons/logo6.svg';
import { ReactComponent as IconHashareWhite } from 'images/icons/logo6white.svg';

import i18n from 'utils/i18n';

import styles from './Footer.module.scss';

interface IProps {
  theme?: 'light' | 'dark';
}

const Footer: React.FC<PropsWithChildren<IProps>> = props => {
  const { theme = 'light' } = props;

  const { t } = useTranslation();
  const isGermanLanguage = i18n.resolvedLanguage === 'de';

  return (
    <>
      <div className={cn(styles.footer, styles[theme])}>
        <div className={styles.footerInner}>
          <div className={styles.footerColumn}>
            {theme === 'dark' ? (
              <IconHashareWhite className={styles.footerLogo} />
            ) : (
              <IconHashare className={styles.footerLogo}></IconHashare>
            )}
            <p>
              {t('copyright')} {new Date().getFullYear()} {t('hashcloudCompanyName')}
            </p>
          </div>
          <div className={styles.footerColumn}>
            <h4 className={styles.footerColumnTitle}>{t('imprint')}</h4>

            <p>
              {t('hashcloudCompanyName')} <br /> {t('hashcloudDirector')}
            </p>
            <p>{t('hashcloudFooterAddress')}</p>
            <p>
              {t('hashcloudFooterRegistryNumber')} <br />
              {t('hashcloudFooterVatId')}
            </p>

            <p>
              <a href="mailto:info@miosga-software.com">info@miosga-software.com</a>
            </p>
          </div>
          <div className={styles.footerColumn}>
            <h4 className={styles.footerColumnTitle}>{t('legalInfromation')}</h4>
            <p>
              <a
                href={
                  isGermanLanguage
                    ? 'https://www.miosga-software.com/Nutzungsbedingungen_DE.pdf'
                    : 'https://www.miosga-software.com/Terms_of_Use_EN.pdf'
                }
                rel="noreferrer"
                target="_blank"
              >
                {t('termsOfUse')}
              </a>
            </p>
            <p>
              <a
                href={
                  isGermanLanguage
                    ? 'https://www.miosga-software.com/Datenschutzerklaerung_DE.pdf'
                    : 'https://www.miosga-software.com/Privacy_Policy_EN.pdf'
                }
                rel="noreferrer"
                target="_blank"
              >
                {t('privacyPolicy')}
              </a>
            </p>
            <p>
              <CookieYesSettingsButton />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
