import { AnimatePresence, motion } from 'motion/react';
import { arrayRemove, doc, updateDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useGlobalState from 'hooks/useGlobalState';

import { ReactComponent as IconHashtag } from 'images/icons/hashtag.svg';
import { ReactComponent as IconNoEntry } from 'images/icons/noEntry.svg';

import { IHashtagData } from 'types/interfaces';

import { db } from 'utils/firestore';
import { showDefaultErrorNotification } from 'utils/notifications';

import CreateNewHashtag from 'components/CreateNewHashtag';
import JoinHashtag from 'components/JoinHashtag';

import styles from './UserMenuHashtags.module.scss';
import userMenuStyles from 'components/UserMenu/UserMenu.module.scss';

const animation = {
  initial: { opacity: 0, x: 15 },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    x: 15,
    transition: {
      duration: 0.2,
    },
  },
};

interface IProps {
  toggleProfileState: (state?: boolean) => void;
}

const UserMenuHashtags: React.FC<PropsWithChildren<IProps>> = props => {
  const { toggleProfileState } = props;

  const { t } = useTranslation();
  const [globalState, setGlobalState] = useGlobalState();
  const { profile } = globalState;

  const [isEditing, toggleEditingMode] = useToggle(false);

  const handleEditModeToggleClick = () => {
    toggleEditingMode();
  };

  const modalParentSelector = () => {
    const outerProfileLayerElement = document.querySelector('[class*="_userMenu_"]');

    return (outerProfileLayerElement || document.body) as HTMLElement;
  };

  const handleHashtagRemoveClick = (hashtag: IHashtagData) => async () => {
    if (!profile) return;

    try {
      const profileDocRef = doc(db, 'profiles', profile?.uid);

      setGlobalState(prevState => {
        if (!prevState.profile) return prevState;

        const newHashtags = prevState.profile.hashtags?.filter(
          profileHashtag => profileHashtag.name !== hashtag.name
        );

        return {
          ...prevState,
          profile: {
            ...prevState.profile,
            hashtags: newHashtags,
          },
        };
      });

      await updateDoc(profileDocRef, { hashtags: arrayRemove(hashtag) });
    } catch (error) {
      showDefaultErrorNotification();
    }
  };

  const handleHashtagLinkClick = (hashtagName: string) => () => {
    const pathnameSlug = window.location.pathname.split('/').find(Boolean);

    if (pathnameSlug?.toLocaleLowerCase() === hashtagName.toLowerCase()) {
      toggleProfileState();
    }
  };

  const hasProfileHashtags = profile?.hashtags && profile.hashtags.length > 0;

  return (
    <>
      {hasProfileHashtags ? (
        <>
          <div
            className={cn(styles.editModeToggle, 'clickable')}
            onClick={handleEditModeToggleClick}
          >
            {t(isEditing ? 'profileHashtagsDoneEditing' : 'profileHashtagsEdit')}
          </div>

          <PerfectScrollbar className={styles.container}>
            <div className={cn(userMenuStyles.panel)}>
              {profile?.hashtags?.map(hashtag => {
                const itemContent = (
                  <>
                    <div className={styles.panelIconContainer}>
                      <IconHashtag className={userMenuStyles.panelIcon}></IconHashtag>
                    </div>
                    <div className={cn(styles.panelItemText)}>
                      <div className={styles.hashtagName}>{hashtag.name}</div>

                      {hashtag.host === profile.uid && (
                        <div className={styles.hashtagOwnerNote}>
                          {t('profileHashtagsOwnHashtag')}
                        </div>
                      )}
                    </div>
                  </>
                );

                return (
                  <div className={styles.panelItemOuter} key={hashtag.name}>
                    <AnimatePresence initial={false}>
                      {isEditing ? (
                        <div className={userMenuStyles.panelItem} title={hashtag.name}>
                          {itemContent}
                        </div>
                      ) : (
                        <Link
                          title={hashtag.name}
                          className={cn(userMenuStyles.panelItem)}
                          to={`/${hashtag.name.toLowerCase()}`}
                          onClick={handleHashtagLinkClick(hashtag.name)}
                        >
                          {itemContent}
                        </Link>
                      )}

                      {isEditing && (
                        <motion.div {...animation} key="a1" className={cn(styles.actions)}>
                          <div
                            className={cn(styles.action, styles.actionRemove)}
                            title={t('profileHashtagsRemoveTooltip', {
                              hashtagName: hashtag.name,
                            })}
                            onClick={handleHashtagRemoveClick(hashtag)}
                          >
                            <IconNoEntry></IconNoEntry>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                );
              })}
            </div>
          </PerfectScrollbar>
        </>
      ) : (
        <div className={cn(userMenuStyles.panel, styles.noData)}>
          <div className={userMenuStyles.panelItem}>
            <div className={styles.panelIconContainer}>
              <IconHashtag className={userMenuStyles.panelIcon}></IconHashtag>
            </div>
            <div className={userMenuStyles.panelItemText}>{t('profileHashtagsEmpty')}</div>
          </div>
        </div>
      )}

      <div className={styles.buttons}>
        <JoinHashtag
          parentSelector={modalParentSelector}
          btnClassName={cn(styles.btn, styles.btnJoin)}
        />

        <CreateNewHashtag
          btnClassName={cn(styles.btn, styles.btnCreate)}
          parentSelector={modalParentSelector}
          htmlOpenClassName="modal-opened"
        />
      </div>
    </>
  );
};

export default UserMenuHashtags;
